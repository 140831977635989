import React, { useEffect, useState } from 'react';
import axios from '../api/axios';

const IDCardList = () => {
  const [idCards, setIDCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/idcards');
        setIDCards(response.data);
      } catch (error) {
        console.error('Error fetching ID cards', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>ID Cards</h1>
      <ul>
        {idCards.map(card => (
          <li key={card._id}>
            <h2>{card.name}</h2>
            <p>{card.position}</p>
            {/* eslint-disable-next-line */}
            <img src={card.photoUrl} alt={`Photo of ${card.name}`} />
            {/* eslint-disable-next-line */}
            <img src={card.qrCode} alt={`QR code for ${card.name}`} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IDCardList;

