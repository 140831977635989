import React, { useState } from 'react';

type MenuIconProps = {
  name: string;
  alt: string;
};

const MenuIcon: React.FC<MenuIconProps> = ({ name, alt }) => {
  const [useFallback, setUseFallback] = useState(false);
  const svgPath = `/assets/icons/navigation/nav-${name}-default.svg`;
  const pngPath = `/assets/icons/navigation/nav-${name}-default.png`;

  const handleError = () => {
    setUseFallback(true);
  };

  return (
    <img
      src={!useFallback ? svgPath : pngPath}
      alt={alt}
      style={{ width: '46px', height: '46px' }}
      onError={handleError}
    />
  );
};

export default MenuIcon;
