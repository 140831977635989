import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://design.verifyidcard.com',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000, // 10 segundos de timeout
});

// Interceptor para manejar errores
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.error('Error de Axios:', error.response || error.message);
    return Promise.reject(error);
  }
);

export default axiosInstance;
