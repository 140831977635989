import React, { useState, useEffect } from 'react';
import { Container, Typography, Alert, CircularProgress } from '@mui/material';
import Canvas from '../components/Canvas';
import axios from '../api/axios';

const DesignCard = () => {
  // eslint-disable-next-line
  const [templates, setTemplates] = useState([]);
  // eslint-disable-next-line
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/templates');
        setTemplates(response.data);
        setError('');
      } catch (err) {
        console.error('Error fetching templates', err);
        setError('Error fetching templates: ' + (err.response?.data?.message || err.message));
      } finally {
        setLoading(false);
      }
    };
    fetchTemplates();
  }, []);
  // eslint-disable-next-line
  const handleLoadTemplate = async () => {
    if (!selectedTemplate) return;
    try {
      setLoading(true);
      const response = await axios.get(`/api/templates/${selectedTemplate}`);
      // Implementación para cargar la plantilla
      console.log(response.data); // Usa la variable para evitar advertencias
      setError('');
    } catch (error) {
      console.error('Error loading template', error);
      setError('Error loading template: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Design Card</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {loading ? (
        <CircularProgress />
      ) : (
        <Canvas />
      )}
    </Container>
  );
};

export default DesignCard;
