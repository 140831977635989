import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Container, TextField, Button, Typography } from '@mui/material';

const ConfirmRegistration = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const email = searchParams.get('email'); // Obtener el email de los parámetros de la URL

  // Función para confirmar el registro
  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Las contraseñas no coinciden');
      return;
    }

    try {
      // Confirmación del registro y redirección al login
      await axios.post('/api/users/confirm-registration', { email, password });
      setMessage('Registro confirmado exitosamente');
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setMessage('Error al confirmar el registro');
    }
  };

  // Función para iniciar sesión
  // eslint-disable-next-line no-unused-vars
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/users/login', { email, password });
      localStorage.setItem('token', response.data.token);
      navigate('/profile'); // Redirección a la página de perfil después del login exitoso
    } catch (error) {
      console.error('Login error', error);
    }
  };

return (
  <Container>
    <Typography variant="h4" gutterBottom>
      Confirmación exitosa
    </Typography>
    <Typography variant="body1">
      Tu correo ha sido confirmado exitosamente.
      {email}
    </Typography>
    <Button onClick={() => navigate('/login')} variant="contained" color="primary">
      Ir a Iniciar Sesión
    </Button>
  </Container>
);
};

export default ConfirmRegistration;
