import React from 'react';
import { Container, Typography } from '@mui/material';

const ConfirmEmail = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Confirma tu correo
      </Typography>
      <Typography variant="body1">
        Un correo de confirmación ha sido enviado a tu dirección de correo electrónico. 
        Por favor, revisa tu bandeja de entrada y sigue las instrucciones.
      </Typography>
    </Container>
  );
};

export default ConfirmEmail;
