import React, { useState } from 'react';
import axios from '../api/axios';

const CreateCardForm = () => {
  const [formData, setFormData] = useState({
    userId: '',
    cardNumber: '',
    issuedDate: '',
    expiryDate: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/cards', formData);
      console.log('Card created:', response.data);
    } catch (error) {
      console.error('Error creating card:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>Create Card</h1>
      <div>
        <label>User ID:</label>
        <input type="text" name="userId" value={formData.userId} onChange={handleChange} required />
      </div>
      <div>
        <label>Card Number:</label>
        <input type="text" name="cardNumber" value={formData.cardNumber} onChange={handleChange} required />
      </div>
      <div>
        <label>Issued Date:</label>
        <input type="date" name="issuedDate" value={formData.issuedDate} onChange={handleChange} required />
      </div>
      <div>
        <label>Expiry Date:</label>
        <input type="date" name="expiryDate" value={formData.expiryDate} onChange={handleChange} required />
      </div>
      <button type="submit">Create Card</button>
    </form>
  );
};

export default CreateCardForm;
