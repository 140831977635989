import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import DesignCard from './pages/DesignCard';
import VerifyCard from './pages/VerifyCard';
import UserList from './components/UserList';
import CreateCardForm from './components/CreateCardForm';
import Register from './components/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import CardTemplates from './components/CardTemplates';
import CardDesigner from './components/CardDesigner';
import Templates from './pages/Templates';
import RegisterClient from './pages/RegisterClient';
import ConfirmRegistration from './pages/ConfirmRegistration';
import ConfirmEmail from './pages/ConfirmEmail';
import ConfirmClientRegistration from './pages/ConfirmClientRegistration';

// Importar Box desde Material-UI
import { Box } from '@mui/material';

function App() {
  return (
    <Router>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/design" element={<DesignCard />} />
            <Route path="/verify" element={<VerifyCard />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/create-card" element={<CreateCardForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/card-templates" element={<CardTemplates />} />
            <Route path="/card-designer" element={<CardDesigner />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/register-client" element={<RegisterClient />} />
            <Route path="/register-user" element={<Register />} />
            <Route path="/confirm-registration" element={<ConfirmRegistration />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/confirm-client-registration" element={<ConfirmClientRegistration />} />
          </Routes>
        </Box>
      </Box>
      <Footer />
    </Router>
  );
}

export default App;
