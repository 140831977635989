import React from 'react';
import { Container, Typography } from '@mui/material';

const Settings = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      {/* Add settings form or options here */}
    </Container>
  );
};

export default Settings;
