import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { TextField, Button, Container, Typography } from '@mui/material';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      console.log('Intentando iniciar sesión como usuario...');
      let response = await axios.post('/api/users/login', { email, password });
      console.log('Respuesta de inicio de sesión de usuario:', response.data);
      
      if (!response.data.token) {
        console.log('Intentando iniciar sesión como cliente...');
        response = await axios.post('/api/clients/login', { email, password });
        console.log('Respuesta de inicio de sesión de cliente:', response.data);
      }

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userType', response.data.userType || 'user');
        const isFirstLogin = !localStorage.getItem('hasLoggedInBefore');
        if (isFirstLogin) {
          localStorage.setItem('hasLoggedInBefore', 'true');
          if (response.data.userType === 'client') {
            navigate('/client-profile');
          } else {
            navigate('/profile');
          }
        } else {
          navigate('/dashboard');
        }
      } else {
        setErrorMessage('No se pudo obtener el token de autenticación.');
      }
    } catch (error) {
      console.error('Error detallado de inicio de sesión:', error.response || error);
      setErrorMessage(error.response?.data?.message || 'Error al iniciar sesión. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Login</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">Login</Button>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </form>
    </Container>
  );
};

export default Login;
