import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Container, TextField, Button, Typography } from '@mui/material';

const ConfirmClientRegistration = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  // Obtener el email de los parámetros de la URL
  const email = searchParams.get('email');

  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Las contraseñas no coinciden');
      return;
    }

    try {
      if (email) {
        await axios.post('/api/clients/confirm-client-registration', { email, password });
        setMessage('Registro confirmado exitosamente');
        setTimeout(() => navigate('/login'), 2000);
      } else {
        setMessage('Correo electrónico no definido.');
      }
    } catch (error) {
      setMessage('Error al confirmar el registro');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Confirmación de Cliente Exitosa
      </Typography>
      <Typography variant="body1">
        Por favor, establece tu contraseña para completar el registro.
      </Typography>
      <form onSubmit={handleConfirmSubmit}>
        <TextField
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirmar Contraseña"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" type="submit">
          Confirmar Registro
        </Button>
      </form>
      {message && <Typography>{message}</Typography>}
    </Container>
  );
};

export default ConfirmClientRegistration;
