import React from 'react';
import IDCardList from '../components/IDCardList';

const Home = () => {
  return (
    <div>
      <h1>Welcome to the ID Card Platform</h1>
      <IDCardList />
    </div>
  );
};

export default Home;

