import React, { useEffect, useState } from 'react';
import axios from '../api/axios'; // Use your configured axios instance

const CardTemplates = () => {
  // eslint-disable-next-line
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState(''); // State for template name
  // eslint-disable-next-line
  const [elements, setElements] = useState([]); // State for template elements

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get('/api/templates'); // Correct endpoint
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);

  const createTemplate = async () => {
    try {
      const response = await axios.post('/api/templates', {
        name: templateName,
        elements: elements, // Use elements from state
        isPublic: true,
      });
      console.log('Template created:', response.data);
      // Optionally update the template list
      setTemplates([...templates, response.data]);
    } catch (error) {
      console.error('Error creating template:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <h2>Plantillas de Carnés</h2>
      <ul>
        {templates.map(template => (
          <li key={template._id}>{template.name}</li>
        ))}
      </ul>
      
      <h3>Crear una Nueva Plantilla</h3>
      <input
        type="text"
        placeholder="Template Name"
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
      />
      <button onClick={createTemplate}>Crear Plantilla</button>
    </div>
  );
};

export default CardTemplates;
