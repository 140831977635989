import React, { useState } from 'react';
import axios from '../api/axios';

const CardDesigner = () => {
  const [design, setDesign] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/cards/create', { design, userId: 'your-user-id' });
      console.log(response); // Utilizar la variable response para evitar la advertencia
      setMessage('Diseño creado con éxito');
    } catch (error) {
      console.error(error); // Imprimir el error para mayor detalle
      setMessage('Error al crear el diseño');
    }
  };

  return (
    <div>
      <h2>Diseñar Carné</h2>
      <form onSubmit={handleSubmit}>
        <textarea value={design} onChange={(e) => setDesign(e.target.value)}></textarea>
        <button type="submit">Guardar Diseño</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CardDesigner;
