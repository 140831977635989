import React, { useState } from 'react';
import axios from '../api/axios';

const VerifyCard = () => {
  const [qrCode, setQrCode] = useState('');
  const [card, setCard] = useState(null);

  const handleVerify = async () => {
    try {
      const response = await axios.get(`/idcards/verify/${qrCode}`);
      setCard(response.data);
    } catch (error) {
      console.error('Error verifying ID card:', error);
      setCard(null);
    }
  };

  return (
    <div>
      <h1>Verify Card</h1>
      <input
        type="text"
        placeholder="Enter QR Code"
        value={qrCode}
        onChange={(e) => setQrCode(e.target.value)}
      />
      <button onClick={handleVerify}>Verify</button>
      {card && (
        <div>
          <h2>{card.name}</h2>
          <p>{card.position}</p>
          <img src={card.photoUrl} alt={card.name} />
          <img src={card.qrCode} alt={card.name} />
        </div>
      )}
    </div>
  );
};

export default VerifyCard;
