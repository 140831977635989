import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Checkbox, FormControlLabel, Typography, Alert } from '@mui/material';
import axios from '../api/axios';

const RegisterClient = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    country: '',
    email: '',
    isOwner: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, isOwner: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    const currentUserId = localStorage.getItem('userId') || 'Admin';
    const formDataWithCreator = {
      ...formData,
      createdBy: currentUserId
    };

    try {
      const response = await axios.post('/api/clients/register-client', formDataWithCreator);
      if (response.status === 201) {
        setSuccess('Registro exitoso. Por favor, verifica tu correo electrónico.');
        const REDIRECT_DELAY_MS = 3000;
        setTimeout(() => {
          navigate(`/confirm-email?email=${encodeURIComponent(formData.email)}`);
        }, REDIRECT_DELAY_MS);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Hubo un error durante el registro. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Registro del Cliente</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nombre"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Apellido"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="País"
          name="country"
          value={formData.country}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Correo"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="email"
          required
        />
        <FormControlLabel
          control={<Checkbox checked={formData.isOwner} onChange={handleCheckboxChange} />}
          label="Certifico que soy el propietario o persona autorizada de la empresa para crear los carnets."
        />
        <Button variant="contained" color="primary" type="submit">
          Registrarme
        </Button>
      </form>
    </Container>
  );
};

export default RegisterClient;
