// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.menu-area {
  width: 46px;
  background-color: #f0f0f0;
  padding: 10px;
}

.function-area {
  width: 242px;
  background-color: #e0e0e0;
  padding: 10px;
}

.work-area {
  flex-grow: 1;
  margin-left: 20px;
  border: 1px solid #000;
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Canvas.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;AACxB","sourcesContent":[".canvas-container {\n  display: flex;\n  width: 100%;\n  margin: 0 auto;\n  background-color: #fff;\n}\n\n.menu-area {\n  width: 46px;\n  background-color: #f0f0f0;\n  padding: 10px;\n}\n\n.function-area {\n  width: 242px;\n  background-color: #e0e0e0;\n  padding: 10px;\n}\n\n.work-area {\n  flex-grow: 1;\n  margin-left: 20px;\n  border: 1px solid #000;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
