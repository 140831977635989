import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Stage, Layer, Text, Image as KonvaImage, Rect, Circle, Transformer } from 'react-konva';
import useImage from 'use-image';
import { saveAs } from 'file-saver';
import axios from '../api/axios'; // Asegúrate que este import esté bien configurado
import '/src/styles/Canvas.css';
import MenuIcon from './menu-icon-component.tsx';

const CanvasImage = ({ imageUrl, ...props }) => {
  const [image] = useImage(imageUrl);
  return <KonvaImage image={image} {...props} />;
};

const Canvas = () => {
  const [elements, setElements] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);

  const stageRef = useRef(null);
  const trRef = useRef(null);

  const undo = useCallback(() => {
    if (history.length > 0) {
      const prevHistory = [...history];
      const lastState = prevHistory.pop();
      setElements(lastState);
      setHistory(prevHistory);
    }
  }, [history]);

  useEffect(() => {
    const handleUndo = (e) => {
      if (e.ctrlKey && e.key === 'z') {
        undo();
      }
    };
    window.addEventListener('keydown', handleUndo);
    return () => window.removeEventListener('keydown', handleUndo);
  }, [undo]);

  const saveHistory = () => {
    setHistory([...history, JSON.parse(JSON.stringify(elements))]);
  };

  const addText = () => {
    const newText = {
      id: `text-${elements.length + 1}`,
      type: 'text',
      x: 50,
      y: 50,
      text: 'Texto',
      fontSize: 20,
      draggable: true,
      fill: 'black',
    };
    saveHistory();
    setElements([...elements, newText]);
  };

  const addImage = (url) => {
    const newImage = {
      id: `image-${elements.length + 1}`,
      type: 'image',
      x: 200,
      y: 50,
      url: url,
      draggable: true,
    };
    saveHistory();
    setElements([...elements, newImage]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      addImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const addRect = () => {
    const newRect = {
      id: `rect-${elements.length + 1}`,
      type: 'rect',
      x: 100,
      y: 100,
      width: 100,
      height: 100,
      fill: 'blue',
      draggable: true,
    };
    saveHistory();
    setElements([...elements, newRect]);
  };

  const addCircle = () => {
    const newCircle = {
      id: `circle-${elements.length + 1}`,
      type: 'circle',
      x: 150,
      y: 150,
      radius: 50,
      fill: 'red',
      draggable: true,
    };
    saveHistory();
    setElements([...elements, newCircle]);
  };

  const handleElementDragEnd = (e, id) => {
    const updatedElements = elements.map(el => {
      if (el.id === id) {
        return {
          ...el,
          x: e.target.x(),
          y: e.target.y(),
        };
      }
      return el;
    });
    setElements(updatedElements);
  };

  const saveDesign = (format) => {
    const stage = stageRef.current;
    let dataURL;
    if (format === 'png' || format === 'jpeg') {
      dataURL = stage.toDataURL({ pixelRatio: 2, mimeType: `image/${format}` });
    } else if (format === 'svg') {
      const svgData = stage.toDataURL({ mimeType: 'image/svg+xml' });
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      saveAs(svgBlob, `design.${format}`);
      return;
    }
    saveAs(dataURL, `design.${format}`);
  };

  const saveTemplate = async () => {
    const templateData = {
      elements: elements,
      name: 'Nombre de la Plantilla',
      isPublic: true, // o false si es privada
    };
    try {
      await axios.post('/api/templates', templateData);
      alert('Template saved successfully');
    } catch (error) {
      console.error('Error saving template', error);
      alert('Error saving template: ' + error.message);
    }
  };

  const loadTemplate = async (templateId) => {
    try {
      const response = await axios.get(`/api/templates/${templateId}`);
      setElements(response.data.elements);
    } catch (error) {
      console.error('Error loading template', error);
      alert('Error loading template: ' + error.message);
    }
  };

  const handleSelect = (e, element) => {
    setSelectedElement(element);
    trRef.current.nodes([e.target]);
    trRef.current.getLayer().batchDraw();
  };

  const sendToBack = () => {
    if (selectedElement) {
      const index = elements.findIndex(el => el.id === selectedElement.id);
      if (index > 0) {
        const updatedElements = [...elements];
        const [element] = updatedElements.splice(index, 1);
        updatedElements.unshift(element);
        setElements(updatedElements);
      }
    }
  };

  const bringToFront = () => {
    if (selectedElement) {
      const index = elements.findIndex(el => el.id === selectedElement.id);
      if (index < elements.length - 1) {
        const updatedElements = [...elements];
        const [element] = updatedElements.splice(index, 1);
        updatedElements.push(element);
        setElements(updatedElements);
      }
    }
  };

  return (
    <div className="canvas-container">
      <div className="menu-area">
        <MenuIcon name="id-card-categories" alt="ID Card Categories" />
        <MenuIcon name="templates" alt="Templates" />
        <MenuIcon name="text" alt="Text" />
        <MenuIcon name="images" alt="Images" />
        <MenuIcon name="shapes" alt="Shapes" />
        <MenuIcon name="cliparts" alt="Cliparts" />
        <MenuIcon name="qr-code" alt="QR Codes" />
        <MenuIcon name="layers" alt="Layers" />
        <MenuIcon name="setting" alt="Setting" />
        <MenuIcon name="my-designs" alt="My Design" />
      </div>
      <div className="function-area">
        <h3>Título del Menú</h3>
        <p>Área funciones del menú</p>
        <button onClick={addText}>Agregar Texto</button>
        <input type="file" onChange={handleFileChange} />
        <button onClick={addRect}>Agregar Rectángulo</button>
        <button onClick={addCircle}>Agregar Círculo</button>
        <button onClick={() => saveDesign('png')}>Guardar PNG</button>
        <button onClick={() => saveDesign('jpeg')}>Guardar JPEG</button>
        <button onClick={() => saveDesign('svg')}>Guardar SVG</button>
        <button onClick={saveTemplate}>Guardar Plantilla</button>
        <button onClick={() => loadTemplate('template-id')}>Cargar Plantilla</button>
        <button onClick={undo}>Deshacer</button>
        <button onClick={sendToBack}>Enviar Atrás</button>
        <button onClick={bringToFront}>Traer Adelante</button>
      </div>
      <div className="work-area">
        <Stage width={800} height={500} ref={stageRef}>
          <Layer>
            {elements.map((el, i) => {
              switch (el.type) {
                case 'text':
                  return (
                    <Text
                      key={i}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                case 'image':
                  return (
                    <CanvasImage
                      key={i}
                      imageUrl={el.url}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                case 'rect':
                  return (
                    <Rect
                      key={i}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                case 'circle':
                  return (
                    <Circle
                      key={i}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                default:
                  return null;
              }
            })}
            <Transformer ref={trRef} />
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

export default Canvas;
