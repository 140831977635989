import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { Container, Typography, List, ListItem, ListItemText, Button } from '@mui/material';

const Templates = () => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get('/api/templates');
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Card Templates</Typography>
      <List>
        {templates.map(template => (
          <ListItem key={template._id}>
            <ListItemText primary={template.name} />
            <Button variant="contained" color="primary">Select</Button>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Templates;
